import React from 'react'
import { Container } from 'react-bootstrap'
import FaqItem from './FaqItem'
import NumberedRow from './Layout/NumberedRow'

const Faq = () => {
    return (
        <section id='faq' className='py-5'>
            <Container>
                <NumberedRow number={2}>
                    <h2 className='h1 mb-5'>Usein kysyttyä</h2>
                    <FaqItem
                        question='Mikä Mobiililahjoitus -palvelu on?'
                        answer='Mobiililahjoitus on uusi ja helppo tapa tehdä lahjoituksia mahdollisimman helposti ja mutkattomasti matkapuhelimella. Voit valita kertaluonteisen tai toistuvan maksun ilman korttia, pankkitiliä tai rekisteröitymistä!'
                    />
                    <FaqItem
                        question='Mihin palvelu soveltuu?'
                        answer='Palvelu voi auttaa sinua luomaan houkuttelevamman ja tuottavamman kampanjan mobiilikäyttäjille.'
                    />
                    <FaqItem
                        question='Mitä hyötyä palvelusta on ja mihin hyöty perustuu?'
                        answer='Mobiililahjoituspalvelu on erinomainen keino rakentaa pitkiä ja kannattavia asiakassuhteita - jotka ovat myös kaikken tuottoisampia. Asiakashankinnan kustannus on usein vakio, mutta tuottoihin vaikuttaa se, kuinka toistuvasti asiakkaasi ostaa. Hyödyt perustuvat myös siihen, että pienten ostosten maksaminen on mahdollisimman helppoa ja mutkatonta.'
                    />
                    <FaqItem
                        question='Miten asiakas pystyy lopettamaan palvelun tilauksen?'
                        answer='Uuden tilausjakson kohdalla asiakas saa tekstiviestin, missä mahdollistetaan palvelun peruuttaminen. Peruutus on tehty myös helpoksi.'
                    />
                    <FaqItem
                        question='Kuinka paljon palvelu maksaa?'
                        answer='Palvelussa on kuukausimaksu, sekä jokaisesta maksutapahtumasta veloitetaan transaktiokohtainen maksu. Palvelun kustannukset ovat operaattorilaskutuksen vuoksi hieman luottokorttikustannuksia arvokkaampia. Palvelussa on pieni kuukausi-, sekä maksutapahtumakohtainen kulu.'
                    />
                    <FaqItem
                        question='Miten Mobiililahjoituspalvelun saa käyttöön ja mitä se sisältää?'
                        answer='Palvelu kattaa kaiken olennaisen mitä tarvitaan maksupalvelun käyttöönotossa. Asetamme palveluun valmiiksi halutut sisällöt ja hinnat. Palveluntarjoajalle jää asiakasvirran ohjaaminen sivulle.'
                    />
                    <FaqItem
                        question='Onko palvelussa “pienellä painettuja” erityisehtoja?'
                        answer='Ei ole. Maksupalvelu vastaa mahdollisista luottotappioista ja valvoo maksutapahtumia automaattisesti mahdollisten väärinkäytösten estämiseksi.'
                    />
                </NumberedRow>
            </Container>
        </section>
    )
}

export default Faq
